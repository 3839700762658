import qs from 'qs'
import { toast } from 'react-toastify'
import { actions, ftpActions, cartActions, authSelectors, userOperations } from '@redux'
import { axiosInstance } from '@config/axiosInstance'
import { checkIfTokenAlive, handleLoadersClosing, handleLoadersOpen } from '@utils'
import i18n from '@src/i18n'
import * as route from '@src/routes'

//GET SERVERS OPERATIONS

const getFtp =
  ({
    p_cnt,
    p_num,
    p_sort,
    p_order,
    signal,
    setIsLoading,
    isLoader = true,
    setPagination,
    setSortBy,
    setFilters,
  }) =>
  (dispatch, getState) => {
    isLoader && handleLoadersOpen(setIsLoading, dispatch)

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'storage',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          clickstat: 'yes',
          p_cnt,
          p_num,
          p_sort,
          p_order,
        }),
        { signal },
      )
      .then(async ({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const elemList = data.doc.elem ?? []

        elemList.forEach(el => {
          if (!el.createdate?.$ && el.createdate?.[0]?.$) {
            const createdate = el.createdate?.[0]?.$
            el.createdate = { $: createdate }
          }
        })

        const ftpRenderData = {
          ftpList: elemList,
          ftpPageRights: data.doc.metadata.toolbar,
        }

        const count = data?.doc?.p_elems?.$ || 0

        const p_cnt = +data.doc.p_cnt.$
        const p_elems = +data.doc.p_elems.$
        const p_num = +data.doc.p_num.$
        const p_sort = data.doc.p_sort.$
        const p_order = data.doc.p_order.$

        const { data: filterData } = await axiosInstance.post(
          '/',
          qs.stringify({
            func: 'storage.filter',
            out: 'json',
            auth: sessionId,
            lang: 'en',
          }),
          { signal },
        )

        setPagination?.({ p_cnt, p_elems, p_num })

        setSortBy({ p_sort, p_order })
        dispatch(ftpActions.setFtpCount(count))
        dispatch(ftpActions.setFTPList(ftpRenderData))

        setFilters?.(filterData.doc)

        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })

      .catch(error => {
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const setFtpFilter =
  ({ values, signal, setIsLoading, successCallback, isLoader = true }) =>
  (dispatch, getState) => {
    isLoader && handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'storage.filter',
          out: 'json',
          auth: sessionId,
          sok: 'ok',
          lang: 'en',
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const getTarifs =
  (setTarifs, data = {}, signal, setIsLoading) =>
  (dispatch, getState) => {
    setIsLoading(true)

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'storage.order',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          ...data,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const { elem: tarifList } = data.doc.list[0]
        const { val: datacenter } = data.doc.slist.length > 1 ? data.doc.slist[0] : []
        const { val: period } = data.doc.slist[0]
        const { $: currentDatacenter } = data.doc.datacenter

        const orderData = {
          tarifList,
          datacenter,
          period,
          currentDatacenter,
        }

        setTarifs(orderData)
        setIsLoading(false)
      })
      .catch(error => {
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const getParameters =
  (period, datacenter, pricelist, setParameters, setFieldValue, signal, setIsLoading) =>
  (dispatch, getState) => {
    setIsLoading(true)

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'storage.order.pricelist',
          out: 'json',
          auth: sessionId,
          period,
          datacenter,
          pricelist,
          snext: 'ok',
          sok: 'ok',
          lang: 'en',
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const { slist: paramsList } = data.doc
        const autoprolong = paramsList?.filter(item => item.$name === 'autoprolong')

        // fields

        setFieldValue('autoprolonglList', autoprolong[0].val)
        setFieldValue('autoprolong', autoprolong[0]?.val[1]?.$key)
        setParameters(paramsList)
        setIsLoading(false)
      })

      .catch(error => {
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const orderFTP = (autoprolong, datacenter, period, pricelist) => (dispatch, getState) => {
  dispatch(actions.showLoader())

  const {
    auth: { sessionId },
  } = getState()

  axiosInstance
    .post(
      '/',
      qs.stringify({
        func: 'storage.order.param',
        out: 'json',
        auth: sessionId,
        period,
        datacenter,
        pricelist,
        autoprolong,
        licence_agreement: 'on',
        clicked_button: 'finish',
        sok: 'ok',
        lang: 'en',
      }),
    )
    .then(({ data }) => {
      if (data.doc.error) throw new Error(data.doc.error.msg.$)

      dispatch(
        cartActions.setCartIsOpenedState({
          isOpened: true,
          redirectPath: route.FTP,
        }),
      )
      dispatch(actions.hideLoader())
    })
    .catch(error => {
      checkIfTokenAlive(error.message, dispatch)
      dispatch(actions.hideLoader())
    })
}

const getPrintLicense = priceId => (dispatch, getState) => {
  const {
    auth: { sessionId },
  } = getState()

  dispatch(actions.showLoader())

  axiosInstance
    .post(
      '/',
      qs.stringify({
        func: 'license.print',
        out: 'doc_print',
        auth: sessionId,
        elid: priceId,
      }),
      { responseType: 'blob' },
    )
    .then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/html' }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)

      dispatch(actions.hideLoader())
    })
    .catch(error => {
      checkIfTokenAlive(error.message, dispatch)
      dispatch(actions.hideLoader())
    })
}

const getCurrentStorageInfo = (elid, setInitialParams) => (dispatch, getState) => {
  dispatch(actions.showLoader())

  const {
    auth: { sessionId },
  } = getState()

  axiosInstance
    .post(
      '/',
      qs.stringify({
        func: 'storage.edit',
        out: 'json',
        auth: sessionId,
        lang: 'en',
        elid,
      }),
    )
    .then(({ data }) => {
      if (data.doc.error) throw new Error(data.doc.error.msg.$)

      const autoprolongList = data.doc.slist.filter(
        item => item.$name === 'autoprolong',
      )[0]
      const payment_method = data.doc.slist.filter(
        item => item.$name === 'stored_method',
      )[0]

      const {
        user,
        autoprolong,
        opendate,
        passwd,
        period,
        name,
        id,
        expiredate,
        createdate,
      } = data.doc

      setInitialParams({
        autoprolongList,
        autoprolong,
        opendate,
        passwd,
        period,
        name,
        user,
        id,
        expiredate,
        createdate,
        payment_method,
      })
      dispatch(actions.hideLoader())
    })
    .catch(error => {
      checkIfTokenAlive(error.message, dispatch)
      dispatch(actions.hideLoader())
    })
}

const editFTP =
  ({ elid, autoprolong, handleModal, successCallback }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'storage.edit',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          autoprolong,
          clicked_button: 'ok',
          sok: 'ok',
        }),
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        toast.success(i18n.t('Changes saved successfully', { ns: 'other' }), {
          position: 'bottom-right',
          toastId: 'customId',
        })

        successCallback?.()
        dispatch(actions.hideLoader())

        handleModal()
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

const getServiceInstruction = (elid, setInstruction) => (dispatch, getState) => {
  dispatch(actions.showLoader())

  const {
    auth: { sessionId },
  } = getState()

  axiosInstance
    .post(
      '/',
      qs.stringify({
        func: 'service.instruction.html',
        out: 'json',
        auth: sessionId,
        lang: i18n.language,
        elid,
      }),
    )
    .then(({ data }) => {
      if (data.doc.error) throw new Error(data.doc.error.msg.$)
      setInstruction(data.doc.body.$)

      dispatch(actions.hideLoader())
    })
    .catch(error => {
      checkIfTokenAlive(error.message, dispatch)
      dispatch(actions.hideLoader())
    })
}

const deleteFtp =
  ({ id, closeFn, successCallback }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'storage.delete',
          auth: sessionId,
          elid: id.join(', '),
          out: 'json',
          lang: 'en',
        }),
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback?.()
        dispatch(userOperations.getNotifyAndServicesCount())
        closeFn()

        toast.success(
          i18n.t('server_deleted_success', { ns: 'other', id: `#${id.join(', #')}` }),
        )

        dispatch(actions.hideLoader())
      })
      .catch(err => {
        checkIfTokenAlive(err.message, dispatch)
        closeFn()

        dispatch(actions.hideLoader())
      })
  }

export default {
  getTarifs,
  getParameters,
  orderFTP,
  getPrintLicense,
  getCurrentStorageInfo,
  editFTP,
  getServiceInstruction,
  deleteFtp,
  getFtp,
  setFtpFilter,
}
