import { PORT_SPEED_PATTERN } from '@src/utils/constants'

export const parsePortSpeed = (parameters, t) => {
  const currentPortSpeed = parameters?.slist
    .find(el => el.$name === 'Port_speed')
    ?.val.find(el => el.$key === parameters?.Port_speed)?.$

  if (!currentPortSpeed)
    return { portSpeedLabel: null, portSpeedTraffic: null, portSpeedPrice: null }

  const match = currentPortSpeed.match(PORT_SPEED_PATTERN)

  if (!match)
    return { portSpeedLabel: null, portSpeedTraffic: null, portSpeedPrice: null }

  const [_, portName, portTraffic, portPrice] = match

  return {
    portSpeedLabel: t(portName.trim()),
    portSpeedTraffic: portTraffic
      ?.trim()
      .replace('unlimited traffic', t('unlimited traffic', { ns: 'dedicated_servers' })),
    portSpeedPrice: portPrice ? ` €${parseFloat(portPrice.trim()).toFixed(2)}` : null,
  }
}

export const parseLicense = (parameters, t) => {
  const currentLicense = parameters?.slist
    .find(el => el.$name === 'Control_panel')
    ?.val.find(el => el.$key === parameters?.Control_panel)?.$

  if (!currentLicense)
    return { licenseLabel: null, domainsCount: null, licensePrice: null }

  const match = currentLicense.match(/\(([^)]+)\)/) // Price in brackets
  const licensePrice = match ? ` €${parseFloat(match[1].trim()).toFixed(2)}` : null

  const sanitizedLicense = currentLicense
    .trim()
    .replaceAll('unlimited traffic', t('unlimited traffic', { ns: 'dedicated_servers' }))
    .replaceAll('Without a license', t('Without a license'))

  // Extract domains count or unlimited
  const domainsMatch = sanitizedLicense.match(/-\s*(\d+|Unlimited)\s*domains/)
  const domainsCount = domainsMatch
    ? domainsMatch[1] === 'Unlimited'
      ? t('Unlimited domains')
      : `${parseInt(domainsMatch[1], 10)} ${t('domains')}`
    : null

  // Clean label
  const licenseLabel = sanitizedLicense
    .replace(/\s*\([^)]+\)/, '') // Remove price brackets
    .replace(/-\s*(\d+|Unlimited)\s*domains/, '') // Remove domains info
    .trim()

  return { licenseLabel, domainsCount, licensePrice }
}

export const parseIpCost = (parameters, t) => {
  const currentIp = parameters?.ipList?.find(
    el => el.value === +parameters?.IP_addresses_count,
  )

  if (!currentIp) return { ipCountLabel: null, ipCost: null }

  return {
    ipCountLabel: `${parameters?.IP_addresses_count} ${t('pcs.', { ns: 'vds' })}`,
    ipCost: ` €${parseFloat(currentIp.cost).toFixed(2)}`,
  }
}
