import qs from 'qs'
import { actions, contarctsActions } from '@redux'
import { axiosInstance } from '@config/axiosInstance'
import { checkIfTokenAlive, handleLoadersClosing, handleLoadersOpen } from '@utils'

const getContracts =
  ({ p_cnt, p_num, p_col, signal, setIsLoading, setPagination }) =>
  (dispatch, getState) => {
    handleLoadersOpen(setIsLoading, dispatch)

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'contract',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          p_cnt,
          p_num,
          p_col,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const contractsRenderData = {
          contracts: data.doc.elem ? data.doc.elem : [],
          // contracts: [],
          contractsPageRights: data.doc.metadata.toolbar,
        }

        dispatch(contarctsActions.setContractsList(contractsRenderData))
        const p_cnt = +data.doc.p_cnt.$
        const p_elems = +data.doc.p_elems.$
        const p_num = +data.doc.p_num.$

        setPagination?.({ p_cnt, p_elems, p_num })
        dispatch(contarctsActions.setContractsList(contractsRenderData))

        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })

      .catch(error => {
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const getPdfFile = (elid, name, signal, setIsLoading) => (dispatch, getState) => {
  const {
    auth: { sessionId },
  } = getState()

  setIsLoading(true)

  axiosInstance
    .post(
      '/',
      qs.stringify({
        func: 'contract.print.pdf',
        out: 'pdf_print',
        auth: sessionId,
        elid,
        lang: 'en',
      }),
      { responseType: 'blob', signal },
    )
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name}.pdf`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)

      setIsLoading(false)
    })
    .catch(error => {
      handleLoadersClosing(error?.message, dispatch, setIsLoading)
      checkIfTokenAlive(error.message, dispatch)
    })
}

const getPrintFile = elid => (dispatch, getState) => {
  const {
    auth: { sessionId },
  } = getState()

  dispatch(actions.showLoader())

  axiosInstance
    .post(
      '/',
      qs.stringify({
        func: 'contract.print.pdf',
        out: 'pdf_print',
        auth: sessionId,
        elid,
        lang: 'en',
      }),
      { responseType: 'blob' },
    )
    .then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)

      dispatch(actions.hideLoader())
    })
    .catch(error => {
      checkIfTokenAlive(error.message, dispatch)
      dispatch(actions.hideLoader())
    })
}

export default {
  getContracts,
  getPdfFile,
  getPrintFile,
}
