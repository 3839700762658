import {
  Button,
  Modal,
  ConnectMethod,
  NotificationMessage,
  PageTabBar,
  OsList,
  Cta,
  Select,
  ScrollToFieldError,
} from '@components'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useReducer, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cloudVpsActions, cloudVpsOperations, cloudVpsSelectors } from '@redux'
import { getInstanceMainInfo } from '@utils'

import {
  DISALLOW_SPACE,
  INSTANCE_PASS_REGEX,
  PASS_REGEX_ASCII,
  DISALLOW_PASS_SPECIFIC_CHARS,
  IMAGE_TYPES,
} from '@utils/constants'

import s from './Modals.module.scss'

const select = 'select_rebuild'

export const RebuildModal = ({
  item,
  data,
  operationSystems,
  zoneList,
  closeModal,
  onSubmit,
}) => {
  const { t } = useTranslation(['cloud_vps', 'auth', 'other', 'vds'])
  const dispatch = useDispatch()

  const { displayName } = getInstanceMainInfo(item)

  const allSshList = useSelector(cloudVpsSelectors.getAllSshList)

  const [state, setState] = useReducer(
    (state, action) => {
      return { ...state, ...action }
    },
    { zone: IMAGE_TYPES.public, [select]: '' },
  )

  let marketplaceCategories = [{ value: 'all', label: t('all', { ns: 'other' }) }]

  const [marketplaceCategory, setMarketplaceCategory] = useState(
    marketplaceCategories?.[0]?.value,
  )

  const [isConnectMethodOpened, setIsConnectMethodOpened] = useState(
    state.zone === IMAGE_TYPES.public,
  )

  /* Object for saving state of every tab */
  const tabStates = useRef({})

  const navSections = useMemo(() => {
    const renderTabs = new Set([...zoneList, IMAGE_TYPES.marketplace] || [])

    return [...renderTabs]?.map(zone => ({
      label: t(`rescue_tab.${zone}`),
      allowToRender: true,
      localValue: zone,
      onLocalClick: () => {
        const savedState = tabStates.current[zone] || ''

        setState({
          [select]: savedState,
          zone,
          passwordType: '',
          password: '',
          ssh_keys: '',
        })

        setIsConnectMethodOpened(zone === IMAGE_TYPES.own ? false : true)
      },
    }))
  }, [data])

  const windowsOS = data?.slist
    ?.find(el => el.$name === select)
    ?.val.filter(el => el.$.includes('Windows'))

  const isWindowsOS = !!windowsOS?.find(el => el.$key === state[select])

  useEffect(() => {
    if (data) {
      const p_cnt = data?.slist.find(el => el.$name === 'ssh_keys')?.val?.length
      dispatch(
        cloudVpsOperations.getSshKeys({
          p_cnt,
          setAllSshItems: list => dispatch(cloudVpsActions.setAllSshList(list)),
        }),
      )
    }
  }, [data])

  useEffect(() => {
    setState({ ssh_keys: allSshList?.[0]?.fleio_key_uuid.$ })
  }, [allSshList])

  /* To save active tab OS on state change */
  useEffect(() => {
    if (state.zone && state[select] !== undefined) {
      tabStates.current[state.zone] = state[select] || ''
    }
  }, [state.zone, state[select]])

  const changeOSHandler = value => {
    setState({ passwordType: '' })
    setState({ [select]: value })
  }

  const validationSchema = Yup.object().shape({
    password:
      state.passwordType === 'password'
        ? Yup.string()
            .matches(DISALLOW_SPACE, t('warnings.disallow_space', { ns: 'auth' }))
            .matches(
              DISALLOW_PASS_SPECIFIC_CHARS,
              t('warnings.disallow_hash', { ns: 'auth' }),
            )
            .min(
              12,
              t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }),
            )
            .max(
              20,
              t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }),
            )
            .matches(
              INSTANCE_PASS_REGEX,
              t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }),
            )
            .matches(PASS_REGEX_ASCII, t('warnings.invalid_ascii', { ns: 'auth' }))
            .test(
              'password-required',
              t('warnings.password_required', { ns: 'auth' }),
              function (value) {
                return state.zone === IMAGE_TYPES.public ? !!value : true
              },
            )
            .required(t('Is a required field', { ns: 'other' }))
        : Yup?.string(),
    password_type:
      state.zone !== IMAGE_TYPES.own &&
      Yup.string().required(t('Is a required field', { ns: 'other' })),
    ssh_keys:
      state.passwordType === 'ssh' &&
      Yup.string()
        .required(t('Is a required field', { ns: 'other' }))
        .test(
          'ssh_validate',
          t('Is a required field', { ns: 'other' }),
          value => value !== 'none',
        ),
    [select]: Yup.string().required(t('Is a required field', { ns: 'other' })),
  })

  const isMarketplaceTab = state.zone === IMAGE_TYPES.marketplace

  const publicImages = operationSystems?.[IMAGE_TYPES.public] || []
  const marketplaceImages = operationSystems?.[IMAGE_TYPES.marketplace] || []
  const ownImages = useMemo(() =>
    operationSystems?.[IMAGE_TYPES.own]?.filter(el => {
      let isImageSizeAcceptable = true

      const instanceMinDisk =
        parseInt(item.details_info?.Disk_space) ||
        parseInt(data?.tarif_params?.$disc) ||
        0
      const instanceMinRam =
        parseInt(item.details_info?.Memory) || parseInt(data?.tarif_params?.$ram) || 0

      isImageSizeAcceptable =
        instanceMinDisk >= +el.$min_disk && instanceMinRam >= +el.$min_ram

      return el.$key !== 'null' && isImageSizeAcceptable
    }),
  )

  marketplaceCategories = marketplaceCategories.concat(
    marketplaceImages?.reduce((acc, el) => {
      if (!acc.some(accEl => accEl.value === el.$marketplace_category)) {
        acc.push({ value: el.$marketplace_category, label: el.$marketplace_category })
      }

      return acc
    }, []),
  )

  let imagesToRender = []
  if (state.zone === IMAGE_TYPES.public) {
    imagesToRender = publicImages
  } else if (state.zone === IMAGE_TYPES.own) {
    imagesToRender = ownImages
  } else if (isMarketplaceTab) {
    imagesToRender =
      marketplaceCategory === 'all'
        ? marketplaceImages
        : marketplaceImages?.filter(
            el => el.$marketplace_category === marketplaceCategory,
          )
  }

  return (
    <Modal isOpen={!!item && !!data} closeModal={closeModal} className={s.rebuild_modal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>
            {t(`rebuild_modal.title.${item.rebuild_action}`)}
          </p>

          <span className={'body_m'}>{displayName}</span>
        </div>
      </Modal.Header>
      <Modal.Body className={s.rebuild_modal__body}>
        <Formik
          enableReinitialize
          initialValues={{
            [select]: state?.[select],
            password: state.password || '',
            password_type: state.passwordType,
            ssh_keys: state.ssh_keys || allSshList?.[0]?.fleio_key_uuid.$,
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            const submitData = values
            submitData.zone =
              state.zone === IMAGE_TYPES.marketplace ? IMAGE_TYPES.public : state.zone

            submitData.enablessh = state.passwordType === 'ssh' ? 'on' : 'off'

            onSubmit(submitData)
          }}
        >
          {({ values, errors, touched }) => {
            return (
              <Form id={'rebuild'}>
                <ScrollToFieldError />

                <div className={s.body}>
                  <NotificationMessage type="warning">
                    {t(`rebuild_modal.warning.${item.rebuild_action}`)}
                  </NotificationMessage>

                  <p className={s.body__text}>
                    {t(`rebuild_modal.text.${item.rebuild_action}`)}
                  </p>

                  <PageTabBar sections={navSections} activeValue={state.zone} isModal />
                  <p>{t(`rebuild_modal.os_description.${state.zone}`)}</p>

                  {isMarketplaceTab && marketplaceCategories && (
                    <div className={s.grid}>
                      <Select
                        itemsList={marketplaceCategories}
                        getElement={setMarketplaceCategory}
                        value={marketplaceCategory}
                        isColored
                      />
                    </div>
                  )}

                  <div className={s.rebuild_list_wrapper}>
                    {imagesToRender?.length > 0 ? (
                      <div className={s.rebuild__os_list}>
                        <OsList
                          value={values[select]}
                          list={imagesToRender}
                          onOSchange={changeOSHandler}
                          isMarketplace={isMarketplaceTab}
                        />
                      </div>
                    ) : (
                      <p>{t('no_images', { ns: 'cloud_vps' })}</p>
                    )}
                    {isWindowsOS && (
                      <NotificationMessage type="warning">
                        {t('windows_os_notice')}
                      </NotificationMessage>
                    )}
                    <ErrorMessage
                      className={s.error_message}
                      name={[select]}
                      component="span"
                    />
                  </div>

                  <div>
                    <ConnectMethod
                      name="password_type"
                      connectionType={state.passwordType}
                      onChangeType={type => setState({ passwordType: type })}
                      setSSHkey={value => setState({ ssh_keys: value })}
                      setPassword={value => setState({ password: value })}
                      errors={errors}
                      touched={touched}
                      sshList={allSshList.map(el => ({
                        label: el.comment.$,
                        value: el.fleio_key_uuid.$,
                      }))}
                      sshKey={values.ssh_keys}
                      password={values.password}
                      isWindows={isWindowsOS}
                      hiddenMode={state.zone === IMAGE_TYPES.own}
                      isOpened={isConnectMethodOpened}
                      setIsOpened={setIsConnectMethodOpened}
                    />
                    <ErrorMessage
                      className={s.error_message}
                      name="password_type"
                      component="span"
                    />
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('Confirm')} size="small" type="submit" form={'rebuild'} />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
