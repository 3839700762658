import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { supportOperations } from '@redux'
import * as Yup from 'yup'
import { IconButton, MessageInput } from '@components'
import { Form, Formik } from 'formik'
import s from './SendMessageForm.module.scss'
import { forwardRef, useEffect, useState } from 'react'

export default forwardRef(function SendMessageForm({ scrollToNewMessage }, ref) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['support', 'other'])
  const params = useParams()

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(t('Is a required field')),
  })

  const sendMessageHandle = (values, { resetForm }) => {
    const message = values.message.replace(/\\/g, '\\\\').replace(/\^/g, '\\^')
    dispatch(
      supportOperations.sendMessage(
        params?.id,
        { ...values, message },
        scrollToNewMessage,
      ),
    )
    resetForm()
  }
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{ message: '', files: [] }}
      onSubmit={sendMessageHandle}
      validateOnBlur={false}
    >
      {({ values, setFieldValue, submitForm }) => {
        let checkItemSize = values?.files.filter(el => el?.size >= 10000000)

        useEffect(() => {
          setIsSubmitDisabled(!!checkItemSize.length)
        }, [checkItemSize.length])

        return (
          <Form className={s.form} ref={ref}>
            <div className={s.fieldsBlock}>
              <MessageInput
                message={values?.message}
                filesError={isSubmitDisabled}
                files={values.files}
                onChangeFiles={value => setFieldValue('files', value)}
                onKeyDown={e => {
                  if (e.code === 'Enter' && !e.shiftKey) {
                    e.preventDefault()
                    if (isSubmitDisabled) return
                    submitForm()
                  }
                }}
                fieldsBlockclassName={s.field_wrapper}
                textareaClassName={s.field}
                errorMessageClassName={s.error_text}
              />

              <IconButton
                color="fourth"
                size="none"
                icon="NewIconSend"
                className={s.submit_btn}
                type="submit"
                disabled={isSubmitDisabled}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
})
