import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'
import { getFormatedDate } from '@utils'

const tracing = {
  id: 'tracing',
  afterDatasetsDraw: chart => {
    const ctx = chart.ctx
    const chartArea = chart.chartArea

    const { lineColor } = chart.config.options.plugins.tracing

    if (chart._active.length) {
      const x = chart.tooltip.caretX
      const y = chart.tooltip.caretY

      // Vertical line
      ctx.save()
      ctx.setLineDash([5, 5])
      ctx.beginPath()
      ctx.moveTo(x, chartArea.top)
      ctx.lineTo(x, chartArea.bottom)
      ctx.lineWidth = 1
      ctx.strokeStyle = lineColor
      ctx.stroke()
      ctx.restore()

      // Horizontal line
      ctx.save()
      ctx.setLineDash([5, 5])
      ctx.beginPath()
      ctx.moveTo(chartArea.left, y)
      ctx.lineTo(chartArea.right, y)
      ctx.lineWidth = 1
      ctx.strokeStyle = lineColor
      ctx.stroke()
      ctx.restore()
    }
  },
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  tracing,
)

export default function IncomeChart({ incomeData }) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  ChartJS.defaults.borderColor = darkTheme ? '#4D4855' : 'rgba(0,0,0,0.1)'
  let labels = []
  let data = []
  if (incomeData?.length > 1) {
    labels = incomeData.map(({ date }) => getFormatedDate({ date }).date)
    data = incomeData.map(({ amount }) => Number(amount.replace(' EUR', '')))
  }

  return (
    <Line
      datasetIdKey="id"
      options={{
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        plugins: {
          tracing: {
            lineColor: darkTheme ? '#fff' : '#392955',
          },

          tooltip: {
            yAlign: 'bottom',
            displayColors: false,
            bodyColor: 'rgba(255, 255, 255, 0.5)',
            titleColor: '#fff',
            backgroundColor: '#2A1C3C',
            padding: {
              left: 12,
              right: 12,
              top: 12,
              bottom: 12,
            },
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.formattedValue} EUR`
              },
            },
          },
          legend: {
            display: false,
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            id: 1,
            label: '',
            data,
            borderColor: '#ff50bf',
            backgroundColor: 'rgba(255, 80, 191, .25)',
            pointBackgroundColor: '#ff50bf',
            pointRadius: 4,
            tension: 0.3,
            fill: true,
          },
        ],
      }}
    />
  )
}
