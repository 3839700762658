import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Icon, Select, InputField, OsList } from '@components'

import {
  DOMAIN_REGEX,
  translatePeriodText,
  roundToDecimal,
  autoprolongList,
  parseIpCost,
} from '@utils'

import { PORT_SPEED_PATTERN } from '@utils/constants'

import cn from 'classnames'

import s from './TariffConfig.module.scss'

export default function TariffConfig({
  parameters,
  setParameters,
  service,
  changeFieldHandler,
}) {
  const { t } = useTranslation(['vds', 'dedicated_servers', 'autoprolong', 'domains'])

  const filterRecipeList = currnetOstempl => {
    const allRecipes = parameters?.slist.find(el => el.$name === 'recipe')?.val
    const filteredRecipes = allRecipes?.filter(
      el => el.$depend === currnetOstempl && el.$key !== 'null',
    )

    return [{ $key: 'null', $: t('without_software') }].concat(filteredRecipes)
  }

  const { values, errors, touched, setFieldTouched } = useFormikContext()

  const getOptionsListExtended = fieldName => {
    if (parameters && parameters.slist) {
      const optionsList = parameters.slist.find(elem => elem.$name === fieldName)?.val
      let firstItem = 0

      return optionsList
        ?.filter(el => el?.$)
        ?.map(({ $key, $, $cost }, index) => {
          let label = ''
          let withSale = false
          let words = []
          let oldPrice

          if (fieldName === 'Memory') {
            words = $?.match(/[\d|.|\\+]+/g)

            if (words?.length > 0 && index === 0) {
              firstItem = words[0]
            }

            if (words?.length > 0 && Number(words[0]) === firstItem * 2) {
              withSale = true
              oldPrice = roundToDecimal(words[1] / 0.45)
            }
          }

          if (withSale && words?.length > 0) {
            label = (
              <span className={s.selectWithSale}>
                <div className={s.sale55Icon}>-55%</div>
                <span className={s.saleSpan}>
                  {`${words[0]} Gb (`}
                  <span className={s.memorySale}>{oldPrice}</span>
                  {translatePeriodText($.trim().split('(')[1], t)}
                </span>
              </span>
            )
          } else if (fieldName === 'Memory' || $.includes('EUR ')) {
            label = translatePeriodText($.trim(), t)
          } else {
            label = t(
              $.trim().replace(
                'unlimited traffic',
                t('unlimited traffic', { ns: 'dedicated_servers' }),
              ),
            )
          }

          let price, traffic, name

          if (fieldName === 'Port_speed') {
            const match = label.match(PORT_SPEED_PATTERN)

            if (match) {
              const [_, portName, portTraffic, portPrice] = match
              price = portPrice
              traffic = portTraffic
              name = portName
            }
          }

          return {
            value: $key,
            label: name || label,
            sale: withSale,
            price: price ? `€ ${parseFloat(price?.trim()).toFixed(2)}` : null,
            additionalInfo: traffic,
            newPrice: roundToDecimal(Number($cost)),
            oldPrice: roundToDecimal(Number($cost) + $cost * 0.55),
          }
        })
    }
    return []
  }

  const getControlPanelList = fieldName => {
    const optionsList = parameters.slist.find(elem => elem.$name === fieldName)?.val

    return optionsList?.map(({ $key, $ }) => {
      const label = translatePeriodText($.trim(), t)

      const splitedLabel = label?.split(' (')

      const translatedLabel = t(splitedLabel?.[0]) + ' (' + splitedLabel?.[1]

      const match = label?.match(/^(.*?)(?: - (.*?))? \((.*?) EUR\)$/)

      let name, price, domains

      if (match) {
        const [_, panelName, panelDomains, panelPrice] = match
        ;(name = panelName), (price = panelPrice), (domains = panelDomains)
      }

      return {
        value: $key,
        label: name ? t(name.trim()) : translatedLabel,
        price: price ? `€ ${parseFloat(price?.trim()).toFixed(2)}` : null,
        additionalInfo: domains
          ?.replace(
            'Unlimited domains',
            t('Unlimited domains', { ns: 'dedicated_servers' }),
          )
          ?.replace('domains', t('domains', { ns: 'dedicated_servers' })),
      }
    })
  }

  const osList = parameters?.slist.find(el => el.$name === 'ostempl')?.val
  const recipeList = filterRecipeList(values.ostempl)

  const validateDomain = value => {
    let error
    if (!value) return
    if (!value.match(DOMAIN_REGEX)) {
      error = t('warning_domain', { ns: 'vds' })
    }
    return error
  }

  return (
    <div className={s.form}>
      {(service === 'vds' || service === 'dedic') && (
        <>
          <div className={cn(s.box, 'box_styles')}>
            <p className={cn('other_btn_uppercase', s.section_title)}>
              {t('os', { ns: 'dedicated_servers' })}
            </p>
            <div className={s.software_OS_List}>
              <OsList
                list={osList}
                onOSchange={value => {
                  setParameters(params => ({
                    ...params,
                    ostempl: { $: value },
                    recipe: { $: 'null' },
                  }))
                }}
                value={values.ostempl}
              />
            </div>
          </div>

          <div className={cn(s.box, 'box_styles')}>
            <p className={cn('other_btn_uppercase', s.section_title)}>
              {t('recipe', { ns: 'dedicated_servers' })}
            </p>
            <div className={s.software_OS_List}>
              <OsList
                list={recipeList}
                onOSchange={value => {
                  setParameters(params => ({
                    ...params,
                    recipe: {
                      $: value,
                      label: recipeList.find(el => el.$key === value)?.$,
                    },
                  }))
                }}
                value={values.recipe}
              />
              {/* {renderSoftwareOSFields('recipe', values.recipe, values.ostempl)} */}
            </div>
          </div>
        </>
      )}

      <div className={cn(s.box, 'box_styles')}>
        <p className={cn('other_btn_uppercase', s.section_title)}>
          {t('characteristics')}
        </p>
        <div className={s.parameters_list}>
          {'Memory' in parameters && (
            <Select
              itemsList={getOptionsListExtended('Memory')}
              value={values.Memory}
              saleIcon={
                <Icon
                  name="SaleFiftyFive"
                  style={{ marginLeft: 7, position: 'absolute', top: -10 }}
                />
              }
              label={`${t('memory')}:`}
              getElement={value => {
                changeFieldHandler({
                  field: 'Memory',
                  value,
                  isUpdatePrice: true,
                  values,
                })
              }}
              isColored
            />
          )}
          {'Control_panel' in parameters && (
            <Select
              value={values.Control_panel}
              itemsList={getControlPanelList('Control_panel')}
              getElement={value => {
                changeFieldHandler({
                  field: 'Control_panel',
                  value,
                  isUpdatePrice: true,
                  values,
                })
              }}
              label={`${t('license_to_panel')}:`}
              isColored
            />
          )}
          {'Disk_space' in parameters && (
            <Select
              value={values.Disk_space}
              itemsList={getOptionsListExtended('Disk_space')}
              getElement={value => {
                changeFieldHandler({
                  field: 'Disk_space',
                  value,
                  isUpdatePrice: true,
                  values,
                })
              }}
              label={`${t('disk_space')}:`}
              isColored
            />
          )}

          {'CPU_count' in parameters && (
            <Select
              value={values.CPU_count}
              itemsList={getOptionsListExtended('CPU_count')}
              getElement={value => {
                changeFieldHandler({
                  field: 'CPU_count',
                  value,
                  isUpdatePrice: true,
                  values,
                })
              }}
              label={`${t('processors')}:`}
              isColored
            />
          )}
          {values.Port_speed && (
            <Select
              value={values.Port_speed}
              itemsList={getOptionsListExtended('Port_speed')}
              getElement={value => {
                changeFieldHandler({
                  field: 'Port_speed',
                  value,
                  isUpdatePrice: true,
                  values,
                })
              }}
              label={`${t('port_speed')}:`}
              isColored
            />
          )}
          {!parameters.autoprolong_unavailable?.$ && (
            <Select
              value={values.autoprolong}
              itemsList={autoprolongList()}
              getElement={value => {
                changeFieldHandler({
                  field: 'autoprolong',
                  value: { $: value },
                  values,
                })
              }}
              label={`${t('autoprolong')}:`}
              isColored
            />
          )}
          {(service === 'vds' || service === 'dedic') && (
            <InputField
              name="domain"
              label={`${t('domain_name', { ns: 'dedicated_servers' })}:`}
              placeholder={t('domain_placeholder', { ns: 'dedicated_servers' })}
              className={s.input_field_wrapper}
              error={!!errors.domain}
              touched={!!touched.domain}
              value={values.domain}
              onChange={e =>
                changeFieldHandler({
                  field: 'domain',
                  value: { $: e.target.value },
                  values,
                })
              }
              onBlur={() => setFieldTouched('domain', true)}
              validate={validateDomain}
            />
          )}

          {'IP_addresses_count' in parameters &&
            (parameters?.ipList?.length === 1 ? (
              <InputField
                name="IP_addresses_count"
                label={`${t('count_ip', { ns: 'dedicated_servers' })}:`}
                className={s.input_field_wrapper}
                disabled
              />
            ) : (
              <Select
                className={s.select}
                placeholder={t('count_ip', { ns: 'dedicated_servers' })}
                value={values?.IP_addresses_count}
                getElement={item =>
                  changeFieldHandler({
                    field: 'IP_addresses_count',
                    value: item,
                    isUpdatePrice: true,
                    values,
                  })
                }
                label={`${t('count_ip', { ns: 'dedicated_servers' })}:`}
                itemsList={parameters?.ipList?.map(el => {
                  const { ipCountLabel, ipCost } = parseIpCost(
                    { ...parameters, IP_addresses_count: el?.value },
                    t,
                  )
                  return {
                    label: ipCountLabel,
                    price: ipCost,
                    value: el?.value?.toString(),
                  }
                })}
                isColored
              />
            ))}

          {'server_name' in parameters && (
            <InputField
              label={`${t('server_name')}:`}
              placeholder={`${t('server_placeholder')}`}
              name="server_name"
              error={!!errors.server_name}
              touched={!!touched.server_name}
              className={s.input_field_wrapper}
              inputClassName={s.text_area}
              autoComplete="off"
              type="text"
              value={values.server_name}
              onChange={e =>
                changeFieldHandler({
                  field: 'server_name',
                  value: { $: e.target.value },
                  values,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}
