import { userSelectors } from '@redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Icon } from '@components'
import { Link } from 'react-router-dom'
import { useCreateTicketOption, usePageRender } from '@utils'
import { useRightsContext } from '@src/context/RightsContext'
import cn from 'classnames'
import s from './DashboardQuickAccessBlock.module.scss'
import * as routes from '@src/routes'

export default function DashboardQuickAccessBlock() {
  const { t } = useTranslation(['other', 'user_settings'])

  const { $need_phone_validate } = useSelector(userSelectors.getUserInfo)
  const { toggleCertificateModal } = useRightsContext()

  const isSupportAllowedToRender = usePageRender('support')
  const isAllowedToRenderUserEdit = usePageRender('customer', 'usrparam')
  const areContractsAllowed = usePageRender('customer', 'contract', false)

  const createTicketOption = useCreateTicketOption()

  return (
    <>
      <section className={cn('box_styles', s.section)}>
        <h4 className="other_btn_uppercase">{t('quick_access')}</h4>

        <div className={s.cardBlock}>
          {$need_phone_validate === 'true' && (
            <Link to={routes.PHONE_VERIFICATION} className={cn('box_styles', s.card)}>
              <h3 className={s.cardTitle}>
                {t('Phone Verification', { ns: 'user_settings' })}
              </h3>

              <div className={s.iconBlock}>
                <div className={s.icon}>
                  <Icon name="PhoneVerificationIcon" />
                </div>
              </div>
            </Link>
          )}

          {areContractsAllowed && (
            <button onClick={toggleCertificateModal} className={cn('box_styles', s.card)}>
              <h3 className={s.cardTitle}>{t('use_certificate')}</h3>

              <div className={s.iconBlock}>
                <div className={s.icon} />
              </div>
            </button>
          )}

          {isSupportAllowedToRender && (
            <button
              onClick={createTicketOption.onClick}
              className={cn('box_styles', s.card)}
            >
              <h3 className={s.cardTitle}>{t('submit_ticket')}</h3>

              <div className={s.iconBlock}>
                <div className={s.icon}>
                  <Icon name="SupportIcon" />
                </div>
              </div>
            </button>
          )}

          {isAllowedToRenderUserEdit && (
            <Link to={routes.USER_SETTINGS_PERSONAL} className={cn('box_styles', s.card)}>
              <h3 className={s.cardTitle}>{t('user_settings')}</h3>

              <div className={s.iconBlock}>
                <div className={s.icon}>
                  <Icon name="NewIconSettings" />
                </div>
              </div>
            </Link>
          )}
        </div>
      </section>
    </>
  )
}
