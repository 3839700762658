import { EXIST_OS_ICON } from './constants'

/**
 * @param {String} distro
 * @returns {String}
 */
export default function getOsRealName(distro) {
  const parts = distro.split(/[-_\s]+/)
  const name = parts.find(part => EXIST_OS_ICON.includes(part.toLowerCase()))

  return name
}
