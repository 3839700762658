import qs from 'qs'
import { toast } from 'react-toastify'
import { axiosInstance } from '@config/axiosInstance'
import { checkIfTokenAlive, handleLoadersClosing, handleLoadersOpen } from '@utils'
import i18n from '@src/i18n'
import * as route from '@src/routes'
import { actions, authSelectors, cartActions, forexActions, userOperations } from '@redux'

//GET hostings OPERATIONS
const getForexList =
  ({
    p_cnt,
    p_num,
    p_sort,
    p_order,
    signal,
    setIsLoading,
    isLoader = true,
    setPagination,
    setSortBy,
    setFilters,
  }) =>
  (dispatch, getState) => {
    isLoader && handleLoadersOpen(setIsLoading, dispatch)

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          clickstat: 'yes',
          p_cnt,
          p_num,
          p_sort,
          p_order,
        }),
        { signal },
      )
      .then(async ({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const elemList = data.doc.elem ?? []

        elemList.forEach(el => {
          if (!el.createdate?.$ && el.createdate?.[0]?.$) {
            const createdate = el.createdate?.[0]?.$
            el.createdate = { $: createdate }
          }
        })

        const forexRenderData = {
          forexList: elemList,
          forexPageRights: data.doc.metadata.toolbar,
        }

        const p_cnt = +data.doc.p_cnt.$
        const p_elems = +data.doc.p_elems.$
        const p_num = +data.doc.p_num.$

        const p_sort = data.doc.p_sort.$
        const p_order = data.doc.p_order.$
        const { data: filterData } = await axiosInstance.post(
          '/',
          qs.stringify({
            func: 'forexbox.filter',
            out: 'json',
            auth: sessionId,
            lang: 'en',
          }),
          { signal },
        )

        setPagination?.({ p_cnt, p_elems, p_num })
        setSortBy({ p_sort, p_order })
        dispatch(forexActions.setForexList(forexRenderData))
        setFilters?.(filterData.doc)
        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })

      .catch(error => {
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const setForexFilter =
  ({ values, signal, setIsLoading, successCallback, isLoader = true }) =>
  (dispatch, getState) => {
    isLoader && handleLoadersOpen(setIsLoading, dispatch)
    const sessionId = authSelectors.getSessionId(getState())

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox.filter',
          out: 'json',
          auth: sessionId,
          sok: 'ok',
          lang: 'en',
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc?.error) throw new Error(data.doc.error.msg.$)
        successCallback()
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
      })
  }

const getTarifs =
  (setTarifs, data = {}, signal, setIsLoading) =>
  (dispatch, getState) => {
    setIsLoading(true)

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox.order',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          ...data,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const slists = {}

        data.doc.slist.forEach(item => {
          slists[item.$name] = item.val
        })

        const datacenter = slists['datacenter'] || []
        const periodList = slists['period'] || []
        const { period } = data.doc

        const { elem: tarifList } = data.doc.list[0]

        const { $: currentDatacenter } = data.doc.datacenter

        const transformedTarifList = tarifList?.map(elem => {
          const currentForexRate = elem?.desc?.$?.split(' ')[2]

          elem.countTerminal = currentForexRate
          elem.countRAM = currentForexRate * 2

          elem.countMemory = currentForexRate * 5 + ' ' + 'Gb'

          elem.osName = 'Windows'

          return elem
        })

        const orderData = {
          transformedTarifList,
          datacenter,
          period,
          periodList,
          currentDatacenter,
        }

        setTarifs(orderData)
        setIsLoading(false)
      })
      .catch(error => {
        if (error.message === 'No tariff plans available for order') {
          setTarifs(error.message)
        }
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const getParameters =
  (period, datacenter, pricelist, setParameters, setFieldValue, signal, setIsLoading) =>
  (dispatch, getState) => {
    setIsLoading(true)

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox.order.pricelist',
          out: 'json',
          auth: sessionId,
          period,
          datacenter,
          pricelist,
          snext: 'ok',
          sok: 'ok',
          lang: 'en',
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const { slist: paramsList, server_package } = data.doc
        const autoprolong = paramsList?.filter(item => item.$name === 'autoprolong')

        // fields

        setFieldValue('autoprolonglList', autoprolong[0].val)
        setFieldValue('autoprolong', autoprolong[0]?.val[1]?.$key)
        setFieldValue('server_package', server_package?.$)

        setParameters({ paramsList })
        setIsLoading(false)
      })

      .catch(error => {
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const orderForex =
  (data = {}) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox.order.param',
          out: 'json',
          auth: sessionId,
          sok: 'ok',
          licence_agreement: 'on',
          clicked_button: 'finish',
          lang: 'en',
          ...data,
        }),
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        dispatch(
          cartActions.setCartIsOpenedState({
            isOpened: true,
            redirectPath: route.FOREX,
          }),
        )

        dispatch(actions.hideLoader())
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

const getPrintLicense = priceId => (dispatch, getState) => {
  const {
    auth: { sessionId },
  } = getState()

  dispatch(actions.showLoader())

  axiosInstance
    .post(
      '/',
      qs.stringify({
        func: 'license.print',
        out: 'doc_print',
        auth: sessionId,
        elid: priceId,
        lang: 'en',
      }),
      { responseType: 'blob' },
    )
    .then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/html' }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)

      dispatch(actions.hideLoader())
    })
    .catch(error => {
      checkIfTokenAlive(error.message, dispatch)
      dispatch(actions.hideLoader())
    })
}

const getCurrentForexInfo =
  (elid, setInitialParams, autoprolong) => (dispatch, getState) => {
    dispatch(actions.showLoader())

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox.edit',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          autoprolong,
        }),
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)

        const autoprolongList = data?.doc?.slist?.filter(
          item => item.$name === 'autoprolong',
        )[0].val
        const paymentMethodList = data?.doc?.slist?.filter(
          item => item.$name === 'stored_method',
        )[0].val

        const serverPackageList = data?.doc?.slist?.filter(
          item => item.$name === 'server_package',
        )[0].val

        const {
          autoprolong,
          opendate,
          period,
          name,
          id,
          expiredate,
          createdate,
          server_ip,
          server_name,
          server_hostname,
          server_package,
          server_password,
          server_user,
          url_rdp,
          stored_method,
          real_expiredate,
          cost,
          costperiod,
        } = data.doc

        setInitialParams({
          autoprolong,
          opendate,
          period,
          id,
          expiredate,
          createdate,
          name,
          server_ip,
          server_name,
          server_hostname,
          server_package,
          server_password,
          server_user,
          paymentMethodList,
          serverPackageList,
          autoprolongList,
          url_rdp,
          stored_method,
          real_expiredate,
          cost,
          costperiod,
        })
        dispatch(actions.hideLoader())
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

const editForex =
  ({
    values,
    elid,
    handleModal,
    signal,
    setIsLoading,
    errorCallback = () => {},
    successCallback,
  }) =>
  (dispatch, getState) => {
    setIsLoading ? setIsLoading(true) : dispatch(actions.showLoader())

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox.edit',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
          clicked_button: 'ok',
          sok: 'ok',
          ...values,
        }),
        { signal },
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)
        successCallback?.()

        toast.success(i18n.t('Changes saved successfully', { ns: 'other' }), {
          position: 'bottom-right',
          toastId: 'customId',
        })

        handleModal && handleModal()

        handleLoadersClosing('closeLoader', dispatch, setIsLoading)
      })
      .catch(error => {
        handleLoadersClosing(error?.message, dispatch, setIsLoading)
        errorCallback()
        checkIfTokenAlive(error.message, dispatch)
      })
  }

const deleteForex =
  ({ elid, handleModal, successCallback }) =>
  (dispatch, getState) => {
    dispatch(actions.showLoader())

    const {
      auth: { sessionId },
    } = getState()

    axiosInstance
      .post(
        '/',
        qs.stringify({
          func: 'forexbox.delete',
          out: 'json',
          auth: sessionId,
          lang: 'en',
          elid,
        }),
      )
      .then(({ data }) => {
        if (data.doc.error) throw new Error(data.doc.error.msg.$)
        successCallback?.()
        dispatch(userOperations.getNotifyAndServicesCount())
        dispatch(actions.hideLoader())
        handleModal()

        toast.success(i18n.t('Service has been successfully removed', { ns: 'other' }), {
          position: 'bottom-right',
          toastId: 'customId',
        })
      })
      .catch(error => {
        checkIfTokenAlive(error.message, dispatch)
        dispatch(actions.hideLoader())
      })
  }

export default {
  getForexList,
  getTarifs,
  getParameters,
  orderForex,
  getPrintLicense,
  getCurrentForexInfo,
  editForex,
  deleteForex,
  setForexFilter,
}
