import Select from 'react-select'
import './ReactSelect.scss'
import cn from 'classnames'
import { ErrorMessageStyled } from '@components'

export default function RSelect({ label, error, touched, name, ...props }) {
  return (
    <div className={cn('rSelect', { error: error && touched })}>
      {label && <label className="other_uppercase_small rSelect__label">{label}</label>}
      <Select classNamePrefix="rSelect" {...props} />
      {name && <ErrorMessageStyled className="error_message" name={name} />}
    </div>
  )
}
