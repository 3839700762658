import {
  Button,
  InputField,
  Modal,
  NotificationMessage,
  PageTabBar,
  OsList,
  Cta,
} from '@components'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useReducer, useRef } from 'react'
import { generatePassword, getInstanceMainInfo } from '@utils'

import {
  DISALLOW_SPACE,
  INSTANCE_PASS_REGEX,
  PASS_REGEX_ASCII,
  DISALLOW_PASS_SPECIFIC_CHARS,
  IMAGE_TYPES,
} from '@utils/constants'

import s from './Modals.module.scss'

const RESCUE_TABS_ORDER = [IMAGE_TYPES.public, IMAGE_TYPES.own, IMAGE_TYPES.shared]
const operationSelect = 'select_boot'

export const RescueModal = ({
  item,
  data,
  operationSystems,
  zoneList,
  closeModal,
  onSubmit,
}) => {
  const { t } = useTranslation(['cloud_vps', 'auth', 'other', 'vds'])

  const { displayName } = getInstanceMainInfo(item)

  const [state, setState] = useReducer(
    (state, action) => {
      return { ...state, ...action }
    },
    { zone: IMAGE_TYPES.public, [operationSelect]: '' },
  )

  const tabStates = useRef({})

  const navSections = useMemo(() => {
    const filteredRescueTabsOrder = RESCUE_TABS_ORDER.filter(tab =>
      zoneList?.includes(tab),
    )

    const renderTabs = new Set([...filteredRescueTabsOrder, ...(zoneList || [])])

    return [...renderTabs]?.map(zone => ({
      label: t(`rescue_tab.${zone}`),
      allowToRender: true,
      localValue: zone,
      onLocalClick: () => {
        const savedState = tabStates.current[zone] || ''

        setState({
          [operationSelect]: savedState,
          zone,
          passwordType: '',
          password: '',
          ssh_keys: '',
        })
      },
    }))
  }, [data])

  /* To save active tab OS on state change */
  useEffect(() => {
    if (state.zone && state[operationSelect] !== undefined) {
      tabStates.current[state.zone] = state[operationSelect] || ''
    }
  }, [state.zone, state[operationSelect]])

  const publicImages = operationSystems?.[IMAGE_TYPES.public] || []

  const ownImages = useMemo(() =>
    operationSystems?.[IMAGE_TYPES.own]?.filter(el => {
      let isImageSizeAcceptable = true

      return el.$key !== 'null' && isImageSizeAcceptable
    }),
  )

  const windowsOS = data?.slist
    ?.find(el => el.$name === operationSelect)
    ?.val.filter(el => el.$.includes('Windows'))

  const isWindowsOS = !!windowsOS?.find(el => el.$key === state[operationSelect])

  const validationSchema = Yup.object().shape({
    password: !isWindowsOS
      ? Yup.string()
          .matches(DISALLOW_SPACE, t('warnings.disallow_space', { ns: 'auth' }))
          .matches(
            DISALLOW_PASS_SPECIFIC_CHARS,
            t('warnings.disallow_hash', { ns: 'auth' }),
          )
          .min(
            12,
            t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }),
          )
          .max(
            20,
            t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }),
          )
          .matches(
            INSTANCE_PASS_REGEX,
            t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }),
          )
          .matches(PASS_REGEX_ASCII, t('warnings.invalid_ascii', { ns: 'auth' }))
          .test(
            'password-required',
            t('warnings.password_required', { ns: 'auth' }),
            function (value) {
              return state.zone === IMAGE_TYPES.public ? !!value : true
            },
          )
          .required(t('Is a required field', { ns: 'other' }))
      : Yup.string(),

    [operationSelect]: Yup.string().required(t('Is a required field', { ns: 'other' })),
  })

  const changeOSHandler = value => {
    setState({ passwordType: '' })
    setState({ [operationSelect]: value })
  }

  let imagesToRender = []
  if (state.zone === IMAGE_TYPES.public) {
    imagesToRender = publicImages
  } else if (state.zone === IMAGE_TYPES.own) {
    imagesToRender = ownImages
  }

  return (
    <Modal isOpen={!!item && !!data} closeModal={closeModal} className={s.rebuild_modal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>
            {t(`rebuild_modal.title.${item.rebuild_action}`)}
          </p>

          <span className={'body_m'}>{displayName}</span>
        </div>
      </Modal.Header>
      <Modal.Body className={s.rebuild_modal__body}>
        <Formik
          enableReinitialize
          initialValues={{
            [operationSelect]: state?.[operationSelect],
            password: state.password || '',
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            const submitData = values
            submitData.zone =
              state.zone === IMAGE_TYPES.marketplace ? IMAGE_TYPES.public : state.zone

            if (isWindowsOS) {
              submitData.password = generatePassword({
                length: 15,
                includeLowerCase: true,
                includeNumber: true,
                includeUpperCase: true,
                includeSymbols: true,
              })
            }
            onSubmit(submitData)
          }}
        >
          {({ values, errors, touched }) => {
            return (
              <Form id={'rebuild'}>
                <div className={s.body}>
                  <p className={s.body__text}>
                    {t(`rebuild_modal.text.${item.rebuild_action}`)}
                  </p>

                  <PageTabBar sections={navSections} activeValue={state.zone} isModal />
                  <p>{t(`rebuild_modal.os_description.${state.zone}`)}</p>

                  <div className={s.rebuild_list_wrapper}>
                    {imagesToRender?.length > 0 ? (
                      <div className={s.rebuild__os_list}>
                        <OsList
                          value={values[operationSelect]}
                          list={imagesToRender}
                          onOSchange={changeOSHandler}
                        />
                      </div>
                    ) : (
                      <p>{t('no_images', { ns: 'cloud_vps' })}</p>
                    )}
                    {isWindowsOS && (
                      <NotificationMessage type="warning">
                        {t('windows_os_notice')}
                      </NotificationMessage>
                    )}
                    <ErrorMessage
                      className={s.error_message}
                      name={[operationSelect]}
                      component="span"
                    />
                  </div>

                  {isWindowsOS ? (
                    <NotificationMessage type="warning">
                      {t('windows_password_warning')}
                    </NotificationMessage>
                  ) : (
                    <InputField
                      className={s.rescue_pass_input}
                      name="password"
                      type="password"
                      label={`${t('temp_password', { ns: 'cloud_vps' })}:`}
                      placeholder={t('new_password_placeholder', { ns: 'vds' })}
                      error={!!errors.password}
                      touched={!!touched.password}
                      isRequired={state.zone === IMAGE_TYPES.public}
                      autoComplete="off"
                      onChange={e => setState({ password: e.target.value })}
                      generatePasswordValue={value => setState({ password: value })}
                    />
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('Confirm')} size="small" type="submit" form={'rebuild'} />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
