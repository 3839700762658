import { useRef, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { RECAPTCHA_KEY } from '@config/config'
import ReCAPTCHA from 'react-google-recaptcha'

import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { authActions, authOperations, authSelectors } from '@redux'
import {
  SelectOfCountries,
  InputField,
  Icon,
  LoaderDots,
  ErrorMessageStyled,
  Cta,
} from '@components'
import * as routes from '@src/routes'
import {
  SPECIAL_CHARACTERS_REGEX,
  CYRILLIC_ALPHABET_PROHIBITED,
  PASS_REGEX,
  PASS_REGEX_ASCII,
  GOOGLE_REGISTRATION_LINK,
  VK_REGISTRATION_LINK,
} from '@utils/constants'

import s from '../AuthStyles.module.scss'
import cn from 'classnames'

const COUNTRIES_WITH_REGIONS = [233, 108, 14]

export default function SignupForm({ geoCountryId, geoStateId }) {
  const { t } = useTranslation('auth')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const recaptchaEl = useRef()

  const globalErrMsg = useSelector(authSelectors.getAuthErrorMsg)

  const [errMsg, setErrMsg] = useState(location?.state?.errMsg || '')
  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(authActions.clearAuthErrorMsg())
    }
  }, [])

  const successRegistration = () => {
    navigate(routes.LOGIN, { state: { from: location.pathname }, replace: true })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(SPECIAL_CHARACTERS_REGEX, t('warnings.special_characters'))
      .required(t('warnings.name_required')),
    email: Yup.string()
      .matches(SPECIAL_CHARACTERS_REGEX, t('warnings.special_characters'))
      .matches(CYRILLIC_ALPHABET_PROHIBITED, t('warnings.cyrillic_prohibited'))
      .email(t('warnings.invalid_email'))
      .required(t('warnings.email_required')),
    password: Yup.string()
      .min(12, t('warnings.invalid_pass', { min: 12, max: 48 }))
      .max(48, t('warnings.invalid_pass', { min: 12, max: 48 }))
      .matches(PASS_REGEX_ASCII, t('warnings.invalid_ascii'))
      .matches(PASS_REGEX, t('warnings.invalid_pass', { min: 12, max: 48 }))
      .required(t('warnings.password_required')),
    passConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], t('warnings.mismatched_password'))
      .required(t('warnings.mismatched_password')),
    reCaptcha: Yup.string()
      .typeError(t('warnings.recaptcha'))
      .required(t('warnings.recaptcha')),
    country: Yup.number()
      .min(1, t('warnings.country_required'))
      .required(t('warnings.country_required')),
    region: Yup.number().when('country', {
      is: val => COUNTRIES_WITH_REGIONS.includes(val),
      then: Yup.number()
        .min(1, t('warnings.region_required'))
        .required(t('warnings.region_required')),
      otherwise: Yup.number(),
    }),
  })
  const partner = Cookies.get('billpartner')
  const sesid = Cookies.get('sesid')
  const referrer = Cookies.get('referrer')

  const handleSubmit = async (values, { setFieldValue }) => {
    const resetRecaptcha = () => {
      recaptchaEl && recaptchaEl?.current?.reset()
      setFieldValue('reCaptcha', '')
    }

    dispatch(
      authOperations.register(
        values,
        partner,
        sesid || referrer,
        setErrMsg,
        successRegistration,
        resetRecaptcha,
      ),
    )
  }

  const isVKAllowed =
    geoCountryId === '182' || geoCountryId === '80' || geoCountryId === '113'

  return (
    <div className={cn('box_styles', s.form_wrapper)}>
      <p className={cn('headings_h1', s.title)}>{t('registration')}</p>

      <Formik
        initialValues={{
          name: location?.state?.name || '',
          email: location?.state?.email || '',
          password: '',
          passConfirmation: '',
          reCaptcha: '',
          country: 0,
          region: geoStateId,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, setFieldTouched, errors, touched, values }) => {
          return (
            <Form className={s.form}>
              {(errMsg || globalErrMsg) && (
                <div
                  className={s.credentials_error}
                  dangerouslySetInnerHTML={{
                    __html: t(errMsg || globalErrMsg),
                  }}
                ></div>
              )}

              <InputField
                datatestid="input_name"
                label={t('name_label')}
                placeholder={t('name_placeholder')}
                // iconLeft="person"
                name="name"
                error={!!errors.name}
                touched={!!touched.name}
                className={s.input_field_wrapper}
                onBlur={e => {
                  const trimmedValue = e.target.value.trim()
                  setFieldValue('name', trimmedValue)
                }}
              />

              <InputField
                datatestid="input_email"
                label={t('email_label')}
                placeholder={t('email_placeholder')}
                // iconLeft="envelope"
                name="email"
                error={!!errors.email}
                touched={!!touched.email}
                className={s.input_field_wrapper}
                autoComplete="off"
                onBlur={e => {
                  const trimmedValue = e.target.value.trim()
                  setFieldValue('email', trimmedValue)
                }}
              />

              <InputField
                datatestid="input_password"
                label={t('password_label')}
                placeholder={t('password_placeholder')}
                // iconLeft="padlock"
                name="password"
                error={!!errors.password}
                touched={!!touched.password}
                type="password"
                className={s.input_field_wrapper}
                autoComplete="new-password"
              />

              <InputField
                datatestid="input_passConfirmation"
                label={t('passConfirmation_label')}
                placeholder={t('passConfirmation_placeholder')}
                // iconLeft="padlock"
                name="passConfirmation"
                error={!!errors.passConfirmation}
                touched={!!touched.passConfirmation}
                type="password"
                className={s.input_field_wrapper}
                autoComplete="new-password"
              />

              <SelectOfCountries
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                geoCountryId={geoCountryId}
                geoStateId={geoStateId}
                autoDetectCounty={true}
                errors={errors}
                touched={touched}
                disabled
                values={values}
              />

              <div className={s.recaptcha_wrapper}>
                {!isCaptchaLoaded && (
                  <div className={s.loaderBlock}>
                    <LoaderDots classname={s.loader} />
                  </div>
                )}

                <ReCAPTCHA
                  className={s.recaptcha}
                  ref={recaptchaEl}
                  sitekey={RECAPTCHA_KEY}
                  asyncScriptOnLoad={() => setIsCaptchaLoaded(true)}
                  onChange={value => {
                    setFieldValue('reCaptcha', value)
                  }}
                />

                <ErrorMessageStyled name="reCaptcha" />
              </div>

              <Cta className={s.submit_btn} buttonType="submit">
                {t('register')}
              </Cta>
            </Form>
          )
        }}
      </Formik>

      <p className={cn('other_uppercase_small', s.social_title)}>{t('register_with')}</p>
      <ul className={s.social_list}>
        {/* <li>
          <a className={s.soc_link} href={}>
            <Icon name="Facebook" width="24px" height="24px" />
          </a>
        </li> */}
        <li>
          <a className={s.soc_link} href={GOOGLE_REGISTRATION_LINK}>
            <Icon name="Google" width="24px" height="24px" />
          </a>
        </li>
        {isVKAllowed && (
          <li>
            <a className={s.soc_link} href={VK_REGISTRATION_LINK}>
              <Icon name="Vk" width="24px" height="24px" />
            </a>
          </li>
        )}
      </ul>

      <p>
        <Trans
          t={t}
          i18nKey="already_have_account"
          components={{
            Link: <Link className={s.pink_link} to={routes.LOGIN} />,
          }}
        />
      </p>
    </div>
  )
}
