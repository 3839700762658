import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import s from './TarifCard.module.scss'
import { Icon, Modal } from '@components'
import { roundToDecimal } from '@utils'

export default function TarifCard(props) {
  const { t } = useTranslation(['virtual_hosting', 'other'])

  const [data, setData] = useState(null)
  const [isDetailModal, setIsDetailModal] = useState(false)
  const { tariff, selected, setPriceHandler, period, isColored } = props

  useEffect(() => {
    const data = {}

    const newArr = tariff?.desc?.$?.split(' |')?.map(el => {
      el = el?.replace('<p>', '')
      el = el?.replace('</p>', '')
      el = el?.replace('\n', '')

      return el
    })

    newArr?.forEach((el, index) => {
      if (index === 0) {
        return (data.name = el)
      } else if (el?.includes('SSD')) {
        el = el?.replace('SSD ', '')
        return (data.ssd = el)
      } else if (el?.includes('Sites')) {
        el = el?.replace('Sites ', '')
        return (data.sites = el)
      } else if (el?.includes('Subdomains')) {
        el = el?.replace('Subdomains ', '')
        return (data.subdomains = el)
      } else if (el?.includes('Database')) {
        el = el?.replace('Database ', '')
        return (data.database = el)
      } else if (el?.includes('ОЗУ') || el?.includes('RAM')) {
        el = el?.replace('ОЗУ ', '')
        el = el?.replace('RAM ', '')
        return (data.ram = el)
      }
    })

    setData(data)
  }, [tariff])

  const parsePrice = price => {
    if (period === 'Trial period') {
      return
    }

    const words = price?.match(/[\d|.|\\+]+/g)
    const amounts = []

    if (words && words.length > 0) {
      words.forEach(w => {
        if (!isNaN(w)) {
          amounts.push(w)
        }
      })
    } else {
      return
    }

    if (amounts?.length === 1) {
      return {
        amount: amounts[0],
      }
    }

    return {
      percent: amounts[0],
      old_amount: roundToDecimal(amounts[1]),
      amount: roundToDecimal(amounts[2]),
    }
  }

  const showDetailModal = e => {
    e.stopPropagation()
    setIsDetailModal(true)
  }

  return (
    <>
      <button
        className={cn('box_styles', s.card, {
          [s.selected]: selected,
          [s.disabled]: period === 'Trial period' && data?.name !== 'AFFORDABLE',
          [s.cardColor]: isColored,
        })}
        onClick={
          selected
            ? null
            : period === 'Trial period' && data?.name !== 'AFFORDABLE'
            ? null
            : setPriceHandler
        }
      >
        <div className={s.tariff_title}>
          {data?.name.toLowerCase()}{' '}
          <Icon onClick={showDetailModal} className={s.info_icon} name="Info" />
        </div>

        <div className={cn('body_s', s.tariff_parameters)}>
          <span className={s.parameter_label}>SSD</span>
          <div>{data?.ssd === 'unlimited' ? <Icon name="Infinity" /> : data?.ssd}</div>

          <span className={s.parameter_label}>{t('Sites')}</span>
          <div>
            {data?.sites === 'unlimited' ? <Icon name="Infinity" /> : data?.sites}
          </div>

          <span className={s.parameter_label}>{t('Subdomains')}</span>
          <div>
            {data?.subdomains === 'unlimited' ? (
              <Icon name="Infinity" />
            ) : (
              data?.subdomains
            )}
          </div>

          <span className={s.parameter_label}>{t('Database')}</span>
          <div>
            {data?.database === 'unlimited' ? <Icon name="Infinity" /> : data?.database}
          </div>

          <span className={s.parameter_label}>{t('RAM')}</span>
          <div>{data?.ram === 'unlimited' ? <Icon name="Infinity" /> : data?.ram}</div>
        </div>

        <div className={s.card_footer}>
          <div
            className={cn(s.tariff_price, {
              [s.freeTrial]: period === 'Trial period' && data?.name === 'AFFORDABLE',
            })}
          >
            {period === 'Trial period' && data?.name !== 'AFFORDABLE'
              ? t(tariff?.price?.$?.trim())
              : period === 'Trial period' && data?.name === 'AFFORDABLE'
              ? `0.00 EUR ${t('for 10 days')}`
              : '€' + parsePrice(tariff?.price?.$)?.amount}
          </div>

          {parsePrice(tariff?.price?.$)?.percent && (
            <span className={cn('body_xs', s.old_price)}>
              €{parsePrice(tariff?.price?.$)?.old_amount}
            </span>
          )}
        </div>
      </button>
      <Modal
        isOpen={isDetailModal}
        closeModal={() => setIsDetailModal(false)}
        isClickOutside
      >
        <Modal.Header>{t('detail_modal_title')}</Modal.Header>
        <Modal.Body>
          <ul className={s.detail_modal_list}>
            <li>
              <Icon name="Check" /> OS CloudLinux, Apache 2.4, LSAPI, Nginx
            </li>
            <li>
              <Icon name="Check" /> ionCube Loader, CURL, Zend, Cron
            </li>
            <li>
              <Icon name="Check" /> {t('detail_modal_backups')}
            </li>
            <li>
              <Icon name="Check" /> {t('detail_modal_ddos')}
            </li>
            <li>
              <Icon name="Check" /> MySQL 5.7 or MySQL 8.0
            </li>
            <li>
              <Icon name="Check" /> PHP: 5.6/7.0/7.1/7.2/7.3/7.4/8.0/8.1/8.2
            </li>
            <li>
              <Icon name="Check" />
              <span data-hosting-parameter>Wordpress toolkit</span>
            </li>
            <li>
              <Icon name="Check" /> {t('detail_modal_panel')} Cpanel
            </li>
            <li>
              <Icon name="Check" /> {t('detail_modal_ftp')}
            </li>
            <li>
              <Icon name="Check" /> phpMyadmin, phpPgAdmin
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  )
}
