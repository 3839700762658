/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { supportOperations } from '@redux'
import { Icon, ImageModal, TooltipWrapper } from '@components'
import s from './MessageItem.module.scss'
import MessageRate from './MessageRate'
import {
  checkIsImageFile,
  decodeHTMLEntities,
  getFileFormat,
  getFormatedDate,
} from '@utils'
export default function Component(props) {
  const dispatch = useDispatch()
  const params = useParams()

  const { message } = props

  const downloadFileHandler = (
    fileName,
    fileId,
    setImg = null,
    setImgIsOpened = null,
  ) => {
    dispatch(supportOperations.getFile(fileName, fileId, setImg, setImgIsOpened))
  }

  const setImageHandler = (id, img) => setImages(prev => [...prev, { id, img }])

  useEffect(() => {
    message?.file?.map(el => {
      downloadFileHandler(
        el?.name?.$,
        el?.param?.$,
        img => setImageHandler(el.param.$, img),
        () => {},
      )
    })
  }, [])

  const [images, setImages] = useState([])
  const [imageIsOpened, setImageIsOpened] = useState(false)

  const closeImageHandler = () => {
    setImageIsOpened(false)
  }
  const { fullDateString } = getFormatedDate({ fullDate: message?.date_post?.$ })

  function stripTags(value) {
    return value?.replace(/<\/?[^>]+(>|$)/g, '')
  }

  return (
    <div
      className={cn(
        s.messageContainer,
        message?.$type === 'outcoming' ? s.outcoming : s.incoming,
      )}
    >
      <img
        className={s.avatar}
        src={`${process.env.REACT_APP_BASE_URL}${message.avatar?.$}`}
        alt={message.avatar?.$name}
      />

      <div
        className={cn(
          'box_styles',
          s.messageBlock,
          message?.$type === 'outcoming' ? s.outcoming : s.incoming,
        )}
      >
        <div className={s.messageHeader}>
          <div className={s.userInfo}>
            <span>{message?.user?.realname?.$}</span>
          </div>
        </div>
        <div className={s.messageBody}>
          <div
            className={s.messageText}
            dangerouslySetInnerHTML={{
              __html: message?.body?.$,
            }}
          >
            {/* {decodeHTMLEntities(stripTags(message?.body?.$))} */}
          </div>
          {message?.file && (
            <div className={s.fileBlock}>
              {message?.file?.map(el => {
                const isImg = checkIsImageFile(el?.name?.$)
                const img = images.find(img => img.id === el.param.$)?.img
                const isOpen = () => {
                  if (isImg) {
                    return setImageIsOpened({ img, name: el?.name?.$ })
                  }
                  return downloadFileHandler(el?.name?.$, el?.param?.$)
                }
                return (
                  <TooltipWrapper
                    content={el?.name?.$}
                    key={el?.param?.$}
                    className={s.tooltip}
                  >
                    <button
                      onClick={isOpen}
                      className={cn(s.file, { [s.file_img]: isImg })}
                    >
                      {isImg ? (
                        <img src={img} alt={el?.name?.$} className={s.img_thumbnail} />
                      ) : (
                        <>
                          <Icon
                            name={`NewIcon${getFileFormat(el?.name.$)}`}
                            className={s.fileIcon}
                            width={60}
                            height={60}
                          />
                        </>
                      )}
                      <span className={s.fileItemName}>{el?.name?.$}</span>
                    </button>
                  </TooltipWrapper>
                )
              })}
            </div>
          )}
        </div>
        <div className={s.footer}>
          {message?.rates && (
            <MessageRate
              postId={params?.id}
              messageId={message?.$id}
              rateStatus={message?.rates?.rate?.$name}
            />
          )}
          <div className={s.datetime}>{fullDateString}</div>
        </div>
      </div>
      <ImageModal imageIsOpened={imageIsOpened} closeImageHandler={closeImageHandler} />
    </div>
  )
}

Component.propTypes = {
  message: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
}

Component.defaultProps = {
  message: null,
}
