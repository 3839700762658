import { Button, InputField, Modal, NotificationMessage, Cta } from '@components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import s from './Modals.module.scss'
import { getInstanceMainInfo } from '@utils'
import {
  DISALLOW_SPACE,
  INSTANCE_PASS_REGEX,
  PASS_REGEX_ASCII,
  DISALLOW_PASS_SPECIFIC_CHARS,
} from '@utils/constants'

export const ChangePasswordModal = ({ item, closeModal, onSubmit }) => {
  const { t } = useTranslation(['cloud_vps', 'auth'])
  const { isWindows, displayName } = getInstanceMainInfo(item)

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(DISALLOW_SPACE, t('warnings.disallow_space', { ns: 'auth' }))
      .matches(DISALLOW_PASS_SPECIFIC_CHARS, t('warnings.disallow_hash', { ns: 'auth' }))
      .min(12, t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }))
      .max(20, t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }))
      .matches(
        INSTANCE_PASS_REGEX,
        t('warnings.invalid_cloud_pass', { min: 12, max: 20, ns: 'cloud_vps' }),
      )
      .matches(PASS_REGEX_ASCII, t('warnings.invalid_ascii', { ns: 'auth' }))
      .required(t('warnings.password_required', { ns: 'auth' })),
  })

  return (
    <Modal isOpen={!!item} closeModal={closeModal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('change_password_title')}</p>

          <span className={'body_m'}>{displayName}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {isWindows ? (
          <NotificationMessage type="warning">
            {t('windows_password_warning')}
          </NotificationMessage>
        ) : (
          <Formik
            initialValues={{ password: '' }}
            onSubmit={values => onSubmit(values.password)}
            validationSchema={validationSchema}
          >
            {({ errors, touched, setFieldValue }) => {
              return (
                <Form id={'change_pass'}>
                  <InputField
                    inputClassName={s.input}
                    name="password"
                    type="password"
                    label={`${t('new_password', { ns: 'vds' })}:`}
                    placeholder={t('new_password_placeholder', { ns: 'vds' })}
                    error={!!errors.password}
                    touched={!!touched.password}
                    isRequired
                    autoComplete="off"
                    generatePasswordValue={value => setFieldValue('password', value)}
                  />
                </Form>
              )
            }}
          </Formik>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          label={t('Confirm')}
          size={'large'}
          type="submit"
          form={'change_pass'}
          disabled={isWindows}
        />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
