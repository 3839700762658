import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { DetailsModal, Table } from '@components'
import { affiliateOperations } from '@redux'

import s from './IncomeTable.module.scss'
import dayjs from 'dayjs'
import roundToDecimal from '@utils/roundToDecimal'
import cn from 'classnames'
import { getFormatedDate } from '@utils'

export default function IncomeTable({ list }) {
  const { t } = useTranslation(['affiliate_program', 'other'])
  const dispatch = useDispatch()
  let incomeSum = 0
  const sortedList = [...list].sort((a, b) => dayjs(b.date) - dayjs(a.date))

  const [details, setDetails] = useState([])

  const onDayClick = date => {
    dispatch(affiliateOperations.getDayDetails(date, setDetails))
  }

  return (
    <>
      <Table className={s.table} isItemsClickable>
        <thead>
          <tr>
            <th>{t('date', { ns: 'other' })}</th>
            <th className={s.amount_cell}>{t('income_section.income_amount')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedList.map(({ amount, date }, index) => {
            incomeSum += Number(amount.replace(' EUR', ''))
            return (
              <tr key={index} onClick={() => onDayClick(date)}>
                <td>{getFormatedDate({ date }).date}</td>
                <td className={s.amount_cell}>{amount}</td>
              </tr>
            )
          })}
          <tr data-tr-transparent data-disabled>
            <td className={s.total}>{t('total', { ns: 'other' })}</td>
            <td className={cn(s.amount_cell, s.total)}>
              {roundToDecimal(incomeSum) + ' EUR'}
            </td>
          </tr>
        </tbody>
      </Table>

      <DetailsModal details={details} closeModal={() => setDetails([])} />
    </>
  )
}
