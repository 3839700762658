import { EXIST_OS_ICON } from './constants'

/**
 * @param {String} distro
 * @param {Boolean} isDarkTheme
 * @returns {String}
 */
export default function getImageIconName(distro, isDarkTheme) {
  if (!distro) return isDarkTheme ? 'linux-logo_dt' : 'linux-logo'

  const parts = distro.toLowerCase().split(/[-_\s]+/)
  const icon = parts.find(part => EXIST_OS_ICON.includes(part))

  return icon
    ? isDarkTheme
      ? `${icon}_dt`
      : icon
    : isDarkTheme
    ? 'linux-logo_dt'
    : 'linux-logo'
}
