import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { InputField, Button, CustomPhoneInput, Modal } from '@components'
import s from './ManageUserForm.module.scss'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { usersOperations } from '@redux'
import cn from 'classnames'

export default function ManageUserForm({
  closeModal,
  datatestid,
  handleSubmit,
  title,
  subtitle,
  formName,
  email,
  userName,
  isOpen,
  isEditUserAllowedToChange,
  userId,
}) {
  const { t } = useTranslation('trusted_users')
  const dispatch = useDispatch()

  const isNewUser = formName !== 'settings'

  const [isPhoneReceived, setIsPhoneReceived] = useState(false)

  const validationSchema = isNewUser
    ? Yup.object().shape({
        ['name' + userId]: Yup.string()
          .matches(
            /^[^!@#$%^&*()\]~+/}[{=?|".':;]+$/g,
            t('trusted_users.form_errors.full_name'),
          )
          .required(t('trusted_users.form_warnings.full_name')),
        ['phone' + userId]: Yup.string()
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            t('trusted_users.form_errors.phone'),
          )
          .min(7, t('trusted_users.form_errors.phone'))
          .required(t('trusted_users.form_warnings.phone')),
        ['email' + userId]: Yup.string()
          .email(t('trusted_users.form_errors.email'))
          .required(t('trusted_users.form_warnings.email')),
        ['password' + userId]: Yup.string()
          .min(12, t('trusted_users.form_errors.password'))
          .max(48, t('trusted_users.form_errors.password_toolong'))
          .required(t('trusted_users.form_warnings.password'))
          .matches(
            /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/,
            t('trusted_users.form_errors.password'),
          ),
        ['passConfirmation' + userId]: Yup.string()
          .oneOf(
            [Yup.ref(`password${userId}`)],
            t('trusted_users.form_errors.conf_password'),
          )
          .required(t('trusted_users.form_warnings.conf_password')),
      })
    : Yup.object().shape({
        ['name' + userId]: Yup.string().matches(
          /^[^!@#$%^&*()\]~+/}[{=?|".':;]+$/g,
          t('trusted_users.form_errors.full_name'),
        ),
        ['phone' + userId]: Yup.string()
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            t('trusted_users.form_errors.phone'),
          )
          .min(7, t('trusted_users.form_errors.phone')),
        ['email' + userId]: Yup.string().email(t('trusted_users.form_errors.email')),
        ['password' + userId]: Yup.string()
          .min(12, t('trusted_users.form_errors.password'))
          .max(48, t('trusted_users.form_errors.password_toolong'))
          .matches(
            /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/,
            t('trusted_users.form_errors.password'),
          ),
        ['passConfirmation' + userId]: Yup.string().oneOf(
          [Yup.ref(`password${userId}`)],
          t('trusted_users.form_errors.conf_password'),
        ),
      })

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} data-testid={datatestid}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{title}</p>

          <span className={'body_m'}>{subtitle}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            ['email' + userId]: '',
            ['name' + userId]: '',
            ['phone' + userId]: '',
            ['password' + userId]: '',
            ['passConfirmation' + userId]: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched, handleBlur, setFieldValue, values }) => {
            useEffect(() => {
              if (isNewUser) return
              dispatch(
                usersOperations.getTrustedUSerInfo({
                  elid: userId,
                  setPhone: value => {
                    setFieldValue('phone' + userId, value.replace(/ /g, ''))
                    setIsPhoneReceived(true)
                  },
                }),
              )
            }, [])

            return (
              <Form
                id="manage-user"
                className={cn(s.form, {
                  [s.hidden]: !isNewUser && !isPhoneReceived,
                })}
              >
                <InputField
                  datatestid="input_email"
                  label={`${t('trusted_users.form.email')}:`}
                  placeholder={
                    formName === 'settings'
                      ? email
                      : t('trusted_users.form_placeholders.email')
                  }
                  name={'email' + userId}
                  error={!!errors['email' + userId]}
                  touched={!!touched['email' + userId]}
                  background={true}
                  autoComplete="off"
                  disabled={formName === 'settings'}
                  isRequired={isNewUser}
                />

                <InputField
                  datatestid="input_name"
                  label={`${t('trusted_users.form.full_name')}:`}
                  placeholder={
                    formName === 'settings'
                      ? userName
                      : t('trusted_users.form_placeholders.full_name')
                  }
                  name={'name' + userId}
                  error={!!errors['name' + userId]}
                  touched={!!touched['name' + userId]}
                  background={true}
                  disabled={formName === 'settings'}
                  isRequired={isNewUser}
                />

                <CustomPhoneInput
                  label={`${t('trusted_users.form.phone')}:`}
                  datatestid="input_phone"
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  name={'phone' + userId}
                  value={values['phone' + userId]}
                  userId={userId}
                  buttonClass={s.phoneInputButton}
                  isRequired={isNewUser}
                />

                <div className={s.password_wrapper}>
                  <InputField
                    datatestid="input_password"
                    label={`${t('trusted_users.form.password')}:`}
                    placeholder={t('trusted_users.form_placeholders.password')}
                    autoComplete="new-password"
                    name={'password' + userId}
                    error={!!errors['password' + userId]}
                    touched={!!touched['password' + userId]}
                    type="password"
                    background={true}
                    isRequired={isNewUser}
                  />

                  <InputField
                    datatestid="input_passConfirmation"
                    label={`${t('trusted_users.form.conf_password')}:`}
                    placeholder={t('trusted_users.form_placeholders.conf_password')}
                    autoComplete="off"
                    name={'passConfirmation' + userId}
                    error={!!errors['passConfirmation' + userId]}
                    touched={!!touched['passConfirmation' + userId]}
                    type="password"
                    background={true}
                    isRequired={isNewUser}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          datatestid="btn_form_submit"
          size="medium"
          className={s.submit_btn}
          label={t('trusted_users.form.submit_btn').toUpperCase()}
          type="submit"
          disabled={!isEditUserAllowedToChange}
          form="manage-user"
        />
      </Modal.Footer>
    </Modal>
  )
}

ManageUserForm.propTypes = {
  closeModal: PropTypes.func,
  datatestid: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  userName: PropTypes.string,
  email: PropTypes.string,
  isOpen: PropTypes.bool,
  formName: PropTypes.string,
  isEditUserAllowedToChange: PropTypes.bool,
  userId: PropTypes.string,
}

ManageUserForm.defaultProps = {
  userId: '',
  isEditUserAllowedToChange: true,
}
