import { useRef, useState } from 'react'
import s from './PaymentsMethodsTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useOutsideAlerter } from '@utils'
import { CardWrapper, Cta, EditCell, InlineOptions, Modal, Options } from '@components'

export default function Component(props) {
  const {
    id,
    name,
    fullname,
    customname,
    num_prolong_items,
    status,
    paymethod_name,
    reconfigHandler,
    deleteItemHandler,
    editItemNameHandler,
  } = props
  const { t } = useTranslation(['billing', 'other', 'domains', 'vds'])
  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })
  const dropDownEl = useRef()
  const [isOpened, setIsOpened] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  const closeMenuHandler = () => {
    setIsOpened(!isOpened)
  }

  useOutsideAlerter(dropDownEl, isOpened, closeMenuHandler)

  const renderStatus = string => {
    const status = string?.trim()
    let color = '#11A63A'
    if (status === 'Configuring') {
      color = '#ED801B'
    } else if (status === 'Enabled') {
      color = '#45A884'
    } else if (status === 'Disabled') {
      color = '#D93F21'
    }
    return {
      color,
    }
  }

  const reconfig = () => {
    reconfigHandler(id, name)
    setIsOpened(false)
  }

  const deleteHandler = () => {
    deleteItemHandler(id)
    setIsOpened(false)
  }

  const editNameHandler = value => {
    editItemNameHandler(id, value)
  }

  const closeDeleteModal = () => setIsDeleteModal(false)
  const openDeleteModal = () => setIsDeleteModal(true)

  const options = [
    {
      label: t('Re-configure'),
      icon: 'Reboot',
      onClick: reconfig,
    },
    {
      label: t('Delete'),
      icon: 'Delete',
      onClick: openDeleteModal,
    },
  ]

  const renderLastColumn = () => {
    return widerThan1024 ? (
      <InlineOptions options={options} />
    ) : (
      <Options options={options} useModalOnMobile />
    )
  }

  const paymentName = t(customname?.trim() || name?.trim() || fullname?.trim(), {
    ns: 'vds',
  })

  return (
    <>
      {widerThan1024 ? (
        <tr>
          <td data-target="name">
            {fullname?.trim() === 'Personal account' ? (
              <span className={s.personal_account}>{paymentName}</span>
            ) : (
              <EditCell
                originName={fullname}
                onSubmit={editNameHandler}
                placeholder={paymentName}
                lettersAmount={25}
                className={s.edit_cell}
              />
            )}
          </td>
          <td data-wrap>{paymethod_name || '-'}</td>
          <td>{num_prolong_items}</td>
          <td style={status ? { color: renderStatus(status).color } : {}}>
            {t(status?.trim() || '-', { ns: 'domains' })}
          </td>
          <td>
            {fullname?.trim() !== 'Personal account' && (
              <div className={s.tableBlockFifth}>{renderLastColumn()}</div>
            )}
          </td>
        </tr>
      ) : (
        <CardWrapper className={s.item}>
          <div className={cn(s.item)}>
            <div className={s.tableBlockFirst}>
              {mobile && <div className={s.item_title}>{t('Name')}:</div>}
              {fullname?.trim() === 'Personal account' ? (
                <span className={s.personal_account}>{paymentName}</span>
              ) : (
                <EditCell
                  originName={fullname}
                  onSubmit={editNameHandler}
                  placeholder={paymentName}
                  lettersAmount={25}
                  className={s.edit_cell}
                />
              )}
            </div>
            <div className={s.tableBlockSecond}>
              {mobile && (
                <div className={s.item_title}>
                  {t('Payment method', { ns: 'other' })}:
                </div>
              )}
              <div className={cn(s.item_text, s.second_item)}>
                {paymethod_name || '-'}
              </div>
            </div>
            <div className={s.tableBlockThird}>
              {mobile && (
                <div className={s.item_title}>
                  {t('Number of auto-renewable services', { ns: 'other' })}:
                </div>
              )}
              <div className={cn(s.item_text, s.third_item)}>{num_prolong_items}</div>
            </div>
            <div className={s.tableBlockFourth}>
              {mobile && (
                <div className={s.item_title}>{t('status', { ns: 'other' })}:</div>
              )}
              <div
                style={status ? { color: renderStatus(status).color } : {}}
                className={cn(s.item_text, s.fourth_item)}
              >
                {t(status?.trim() || '-', { ns: 'domains' })}
              </div>
            </div>
            {fullname?.trim() !== 'Personal account' && (
              <div className={s.tableBlockFifth}>{renderLastColumn()}</div>
            )}
          </div>
        </CardWrapper>
      )}

      <Modal isOpen={isDeleteModal} closeModal={closeDeleteModal} isClickOutside>
        <Modal.Header>
          <div>
            <p className={'headings_h2'}>{t('delete_payment_method_title')}</p>

            <span className={'body_m'}> {paymentName}</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>{t('delete_payment_method_text')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Cta onClick={deleteHandler} buttonType="button" view="primary">
            {t('delete', { ns: 'other' })}
          </Cta>
          <Cta onClick={closeDeleteModal} buttonType="button" view="secondary">
            {t('Cancel', { ns: 'other' })}
          </Cta>
        </Modal.Footer>
      </Modal>
    </>
  )
}
Component.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  unread: PropTypes.bool,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
}

Component.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  unread: false,
  setSelctedTicket: () => null,
  selected: null,
}
